import React, { Fragment } from 'react';
import PropTypes, { bool } from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { ErrorBoundary } from "react-error-boundary";

import Joyride, {
  CallBackProps, STATUS,
} from 'react-joyride';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';
import Spinner from 'reactstrap/lib/Spinner';

import Button from 'reactstrap/lib/Button';
import Label from 'reactstrap/lib/Label';
import TabPane from 'reactstrap/lib/TabPane';
import TabContent from 'reactstrap/lib/TabContent';

import CustomModal from '../Components/Components/Modal';

import Clock from '../Components/Components/Clock';

import LoadingPage from '../Components/Pages/Loading';
import BlockedPage from '../Components/Pages/Blocked';

import RulesPage from './RulesPage/RulesPage';
import TermsOfUsePage from './TermsOfUsePage';

import RoomsTable from './RoomsTable/RoomsTable';
import RoomsMobileTable from './RoomsTable/Mobile/RoomsTable';
import BonusPage from './BonusPage';
import BonusMobilePage from './Mobile/BonusPage';
import BuyPage from './BuyPage';
import BuyMobilePage from './Mobile/BuyPage';
import TopPage from './Top/TopPage';
import TopPageMobile from './Top/Mobile/TopPage';
import Tournaments from './Tournaments/Tournaments';
import TournamentsMobile from './Tournaments/Mobile/Tournaments';
import TournamentsHistory from './Tournaments/TournamentsHistory';
import TournamentsHistoryMobile from './Tournaments/Mobile/TournamentsHistory';
import TournamentResults from './Tournaments/TournamentResults';
import TournamentNotification from './Tournaments/TournamentNotification';
import EventModals from './Modals/EventModals';
import EventMobileModals from './Modals/Mobile/EventModals';
import Feedback from './Modals/Feedback';
import Cookies from './Modals/Cookies';
import PowerRankings from './PowerRankings/PowerRankings';
import PowerRankingsMobile from './Mobile/PowerRankings';

import MoneyHistory from './Modals/MoneyHistory';
import PointsHistory from './Modals/PointsHistory';
import BannedUsers from './Modals/BannedUsers';
import HolidayModals from './Modals/HolidayModals';
import BonusModals from './Modals/BonusModals';
import NewVersion from './Modals/NewVersion';
import UserSettings from './Modals/UserSettings';
import AboutUs from './Modals/AboutUs';
import DropdownMenuModalContent from './Modals/DropdownMenuModalContent';


import MyInfo from './MyInfo/MyInfo';
import MyInfoMobile from './MyInfo/Mobile/MyInfo';
import Friends from './MyInfo/Friends';
import FriendsMobile from './MyInfo/Mobile/Friends';
import IgnoredUsers from './MyInfo/IgnoredUsers';
import IgnoredUsersMobile from './MyInfo/Mobile/IgnoredUsers';
import Achievements from './MyInfo/Achievements';
import AchievementsMobile from './MyInfo/Mobile/Achievements';
import AchievementsModal from './MyInfo/AchievementsModal';
import GamesHistory from './MyInfo/GamesHistory/GamesHistory';
import GamesHistoryMobile from './MyInfo/Mobile/GamesHistory';
import WeeklyStatusHome from './MyInfo/WeeklyStatusHome';
import WeeklyStatus from './MyInfo/WeeklyStatus';
import WeeklyStatusMobile from './MyInfo/Mobile/WeeklyStatus';
import GameLogs from './MyInfo/GameLogs';
import GameLogsMobile from './MyInfo/Mobile/GameLogs';

import CustomTooltip from '../Components/Components/CustomTooltip';
import Notification from '../Components/Components/Notifications';
import JoyrideComponent from './Tutorial/JoyrideComponent';
import JoyrideComponentMobile from './Tutorial/Mobile/JoyrideComponent';


import OnlineCount from '../Components/Layout/OnlineCount';
import TopSettingsSection from '../Components/Layout/TopSettingsSection';

import LayoutHeader from './Layout/LayoutHeader';
import LayoutMobileHeader from './Layout/Mobile/LayoutHeader';
// import LandScapeLayoutMobileHeader from './Layout/Mobile/LandScapeLayoutMobileHeader';
import LayoutFooter from './Layout/LayoutFooter';
import LayoutMobileFooter from './Layout/Mobile/LayoutFooter';
import BottomBar from './Layout/BottomBar';

import * as constants from '../../../../constants/constants';
import 'react-circular-progressbar/dist/styles.css';

import config from '../../../../constants/config';
import chatInSound from '../../../../sounds/chat_notification.wav';
import buttonClickedSound from '../../../../sounds/button.mp3';
import logout from '../../../../images/redesign/common/logout.svg';
import humanSmallImg from '../../../../images/redesign/tutorial/human-small.png';
import chatLinkImg from '../../../../images/redesign/icons/chat.svg'
import chatLinkLightImg from '../../../../images/redesign/light-mode/icons/chat-light-icon.svg';
import debuggerImg from '../../../../images/debugIco.svg';
import { AiOutlineMore } from "react-icons/ai";

// import openFullscreen from '../../../images/icons/openFullscreenW.webp';
// import closeFullscreen from '../../../images/icons/closeFullscreenW.webp';
import {
  getAchievements,
  claimAchievement,
  initEveryPayPayment,
  setUserScreenView,
  closeLevelNotification,
  saveErrorCode,
} from '../../../../actions/member';

import { returnToAdminReturnToken } from '../../../../actions/users';
import {
  resetRoomError, setTournamentRoomEnded,
} from '../../../../actions/room';

import { filter, map, size } from 'lodash';
import TournamentsPlan from './Tournaments/TournamentsPlan';
import TournamentsPlanMobile from './Tournaments/Mobile/TournamentsPlan';
import { Media } from 'reactstrap/lib';
import { setRoomEnded } from '../../../../actions/room';
import RoomEndedModal from './Modals/RoomEndedModal';
import PaymentTypeContent from './Modals/PaymentTypeContent';
import Hints from './Modals/Hints';
import IconPopover from '../Components/Components/IconPopover';
import LandScapeLayoutMobileHeader from './Layout/Mobile/LandScapeLayoutMobileHeader';
import { setActiveTab, setIgnoredSwitchChecked, setJoyrideEndedTrigger, setPwOption } from '../../../../actions/state';
import CustomDropdown from '../Components/Components/CustomDropdown';
import { DropdownItem, Row } from 'reactstrap';
import coinImg from '../../../../images/redesign/common/coin.svg';
import ratingImg from '../../../../images/redesign/common/rating.svg';
import PlayerAvatar from '../Components/Components/PlayerAvatar';
import { getCampaignOpenRoomBanner, getCampaignLobbyBanner } from '../../../../actions/shop';
import TournamentsHeader from './Tournaments/TournamentsHeader';
import MyInfoSubHeader from './MyInfo/Mobile/MyInfoSubHeader';
import TournamentsSubHeader from './Tournaments/Mobile/TournamentsSubHeader';
import { isLandscape, isMobileScreenResolution } from '../../../../utils/screen';
import NewHelpPage from './RulesPage/NewHelpPage';

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  console.log('fallbackRender', error);

  // saveErrorCode(error);

  return (
    <div role="alert">
      <p>Kļūda:</p>
      <pre>{error.message}</pre>
      <pre>{error.code}</pre>
    </div>
  );
}

let fallbackErr5 = false;

function fallbackRender5({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  console.log('fallbackRender5', error);

  fallbackErr5 = true;
  // saveErrorCode(error);

  return (
    <div role="alert">
      <p>Kļūda:</p>
      <pre>{error.message}</pre>
      <pre>{error.code}</pre>
    </div>
  );
}


class Menu extends React.Component {
  static propTypes = {
    setRoomEndedFunction: PropTypes.func.isRequired,
    setTournamentRoomEndedFunction: PropTypes.func.isRequired,
    initEveryPay: PropTypes.func.isRequired,
    socProvider: PropTypes.string,
    hasActiveRegistration: PropTypes.bool,
    myTournamentsData: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    insufficientBalanceAmount: PropTypes.number,
    insufficientBalanceBet: PropTypes.string,
    soundOn: PropTypes.bool,
    joinRoom: PropTypes.func.isRequired,
    createRoom: PropTypes.func.isRequired,
    uid: PropTypes.string,
    loadingProgress: PropTypes.number.isRequired,
    member: PropTypes.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    resetErrorNotif: PropTypes.func.isRequired,
    errorNotification: PropTypes.string,
    ignoredMessageName: PropTypes.string,
    ignoredMessageUid: PropTypes.string,
    disableFirstTimeNotif: PropTypes.func.isRequired,
    disableTutorial: PropTypes.func.isRequired,
    setSupportAsRead: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    closePrivateRoomPassword: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    updateLastAction: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    toggleFullscreen: PropTypes.func.isRequired,
    fetchBalanceHistory: PropTypes.func.isRequired,
    fetchPointsHistory: PropTypes.func.isRequired,
    privateRoomPassword: PropTypes.arrayOf(PropTypes.shape()),
    showPrivateRoomPassword: PropTypes.func.isRequired,
    requestDeleteUser: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired,
    updateAvatarFileUpload: PropTypes.func.isRequired,
    emailVerifySuccess: PropTypes.bool,
    toggleNewDesign: PropTypes.func.isRequired,
    weeklyDataOpen: PropTypes.bool,
    closeWeeklyData: PropTypes.func.isRequired,
    cookiesModalOpen: false,
    screenMode: PropTypes.string,
    toggleScreenMode: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    toggleFont: PropTypes.func.isRequired,
    userAchievements: PropTypes.shape(),
    fetchAchievements: PropTypes.func.isRequired,
    claimedAchievements: PropTypes.shape(),
    claimAchievementFunc: PropTypes.func.isRequired,
    gamesPlayed: PropTypes.number,
    gamesWon: PropTypes.number,
    roomEnded: PropTypes.bool,
    tournamentRoomEnded: PropTypes.bool,
    balance: PropTypes.number,
    closeLevelUpNotification: PropTypes.func.isRequired,
    resetFilterRooms: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
    isEventOption: PropTypes.bool,
    toggleEvent: PropTypes.func.isRequired,
    checkWeeklyDataOpenFunc: PropTypes.func.isRequired,
    setJoyrideEndedTriggerFunc: PropTypes.func.isRequired,
    joyrideEndedTrigger: PropTypes.string,
    setScreenView: PropTypes.func.isRequired,
    role: PropTypes.string,
    lastShopTab: PropTypes.string,
    transactionComplete: PropTypes.bool,
    setActiveTabFunc: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
    setPwOptionFunc: PropTypes.func.isRequired,
    pwOption: PropTypes.bool.isRequired,
    ignoredSwitchChecked: PropTypes.bool.isRequired,
    setIgnoredSwitchCheckedFunc: PropTypes.func.isRequired,
    returnToAdmin: PropTypes.func.isRequired,
  }

  static defaultProps = {
    uid: '',
    errorNotification: '',
    ignoredMessageName: '',
    ignoredMessageUid: '',
    hasActiveRegistration: false,
    myTournamentsData: {},
    notificationSettings: {},
    privateRoomPassword: [],
    isFullscreen: false,
    isFullscreenEnabled: false,
    insufficientBalanceAmount: null,
    insufficientBalanceBet: null,
    soundOn: false,
    emailVerifySuccess: false,
    weeklyDataOpen: false,
    fontMode: constants.FONT_MODE.normal,
    userAchievements: {},
    claimedAchievements: {},
    gamesPlayed: 0,
    gamesWon: 0,
    screenMode: constants.SCREEN_MODE.normal,
    socProvider: null,
    isEventOption: false,
    role: null,
    designPlatform: PropTypes.shape({}),
    platform: PropTypes.string,
    campaignData: PropTypes.shape(),
    fetchCampaign: PropTypes.func.isRequired,
    activeTab: constants.MENU_NAVIGATION.rooms,
    pwOption: false,
    ignoredSwitchChecked: false,
  }

  constructor(props) {
    super(props);

    const { t } = props;
    let devMode = false;
    try {
      const { hostname } = window.location;
      if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
        devMode = true;
      }
    } catch (err) {}

    this.state = {
      // activeTab: '1',
      collapedTab: null,
      activeTabNoContent: '',
      // collapsedTabNoContent: null,
      rulesModalOpen: false,
      supportModalOpen: false,
      balanceHistoryModalOpen: false,
      pointsHistoryModalOpen: false,
      aboutModalOpen: false,
      editModalOpen: false,
      run: false,
      achievementsNotification: false,
      helpPageOpen: false,
      insufficientBalanceAmount: null,
      insufficientBalanceBet: null,
      //  showTournamentTutorial: false,
      stripeLoading: false,
      cookiesFrom: "",
      holidayModalOpen: false,
      bonusModalOpen: false,
      openAchievementsModal: false,
      claimAchieveProcess: false,
      mobileDropdownModalOpened: false,
      buyModal: false,
      paymentErrorModal: false,
      selectedProduct: {},
      selectedPaymentOption: constants.PAYMENT_TYPES.swedbank,
      isOnline: navigator.onLine,
      checkedTerms: false,
      paymentError: '',
      windowHeight: undefined,
      windowWidth: undefined,
      roomEndedModalBlock: false,
      mainSettingsPopoverOpen: false,
      supportPopoverOpen: false,
      tutorialPopoverOpen: false,
      openHolidayDebugger: false,
      selectedTestHoliday: [constants.HOLIDAY_TYPES[0]],
      holidayTest: false,
      designPlatform: {},
      platform: constants.DWEB_PLATFORM,
      devMode,
      isLandscapeVal: isLandscape(),
      isMobileScreenResolutionVal: isMobileScreenResolution(),
      openNewHelpModal: false,
      lastPlayerChangeModalClosed: false,
    };

    this.chatInAudio = new Audio(chatInSound);
    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentWillMount() {
    console.log('componentWillMount');
    const { member, emailVerifySuccess, checkWeeklyDataOpenFunc } = this.props;

    // if (member && member.tutorialShown) {
    //   this.setState({ run: true });
    // }

    try {
      if (emailVerifySuccess) {
        this.setState({
          toggleVerifySuccess: true,
        });
      }

      if (member && member.uid) {
        checkWeeklyDataOpenFunc();
      }
    } catch (err) {
      console.log('err 1');
    }
  }

  componentDidMount() {
    try {
      window.addEventListener('resize', this.handleResizeJoyride);
      this.handleResize();
    } catch {
      console.log('joyride resize err')
    }


    const { fetchCampaign, } = this.props;
    const { devMode } = this.state;

    try {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
    } catch (err) {
      console.log('err 2');
    }

    try {
      if (!localStorage.getItem('zoleCookies') && !config.isInAppFrame()) { //x
        this.setState({
          cookiesModalOpen: true,
        });
      }
    } catch (err) {
      console.log('err 3');
    }

    try {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
      console.log('fetchCampaign');
      fetchCampaign('lobby', 'new', devMode);
    } catch(err) {
      console.log('err 1');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      member,
      errorNotification,
      insufficientBalanceAmount,
      insufficientBalanceBet,
      soundOn,
      balance,
      weeklyDataOpen,
      checkWeeklyDataOpenFunc,
      joyrideEndedTrigger,
      setJoyrideEndedTriggerFunc,
      closeLevelUpNotification,
      tournamentRoomEnded,
      setTournamentRoomEndedFunction,
      lastShopTab,
      transactionComplete,
      activeTab,
      setActiveTabFunc,
      adminReturnToken,
    } = this.props;

    const { openModal, modalType, run } = prevState;
    const { member: oldMember, balance: oldBalance, joyrideEndedTrigger: oldJoyrideEndedTrigger, } = prevProps;

    if (lastShopTab && transactionComplete && activeTab !== constants.MENU_NAVIGATION.buyPage) {
      setActiveTabFunc(constants.MENU_NAVIGATION.buyPage);
    }

    if (joyrideEndedTrigger && !oldJoyrideEndedTrigger) {
      setActiveTabFunc(constants.MENU_NAVIGATION.rooms);
      setJoyrideEndedTriggerFunc(false);
    }

    if (oldBalance !== balance) {
      this.setState({ roomEndedModalBlock: false });
    }

    if (tournamentRoomEnded && activeTab !== constants.MENU_NAVIGATION.tournaments) {
      this.toggle(constants.MENU_NAVIGATION.tournaments);
      setTournamentRoomEndedFunction(false);
    }

    if (errorNotification) {
      if (errorNotification === 'insufficient balance') {
        if (!(openModal && modalType === 'noBalanceMenu')) {
          this.UpdateModal(true, 'noBalanceMenu', null, insufficientBalanceAmount, insufficientBalanceBet);
        }
      } else if (errorNotification === 'pro room') {
        if (!(openModal && modalType === 'proRoomMenu')) {
          this.UpdateModal(true, 'proRoomMenu', null, null, null);
        }
      } else if (errorNotification === 'pro bet') {
        if (!(openModal && modalType === 'proBetMenu')) {
          this.UpdateModal(true, 'proBetMenu', null, null, null);
        }
      } else if (errorNotification === 'insuf bal tournament') {
        if (!(openModal && modalType === 'noBalanceTournament')) {
          this.UpdateModal(true, 'noBalanceTournament', null, null, null);
        }
      } else if (errorNotification === 'You ignored player') {
        if (!(openModal && modalType === 'youIgnoredPlayer')) {
          this.UpdateModal(true, 'youIgnoredPlayer', null, null, null);
        }
      } else if (errorNotification === 'Player ignored you') {
        if (!(openModal && modalType === 'playerIgnoredYou')) {
          this.UpdateModal(true, 'playerIgnoredYou', null, null, null);
        }
      } else if (errorNotification === 'email not verified') {
        if (!(openModal && modalType === 'emailNotVerified')) {
          this.UpdateModal(true, 'emailNotVerified', null, null, null);
        }
      } else if (errorNotification === 'tournament already registered') {
        if (!(openModal && modalType === 'tournamentAlreadyRegistered')) {
          this.UpdateModal(true, 'tournamentAlreadyRegistered', null, null, null);
        }
      } else if (errorNotification === 'tournament max players') {
        if (!(openModal && modalType === 'tournamentMaxPlayers')) {
          this.UpdateModal(true, 'tournamentMaxPlayers', null, null, null);
        }
      } else if (errorNotification === 'already registered in another tournament') {
        if (!(openModal && modalType === 'alreadyRegisteredAnotherTournament')) {
          this.UpdateModal(true, 'alreadyRegisteredAnotherTournament', null, null, null);
        }
      }
    }

    if (member && member.lvlUpNotification && !adminReturnToken) {
      if (member.level === 1) {
        closeLevelUpNotification();
      } else {
        if (!(openModal && modalType === 'levelUp')) {
          this.UpdateModal(true, 'levelUp', member.level, null, null);
        }
      }
    }

    if (member && !member.lvlUpNotification && openModal && modalType === 'levelUp') {
      this.UpdateModal(false, '', null, null, null);
    }

    if (member && member.uid && !member.tutorialShown && !run) {
      this.runJoyride();
    }

    if (member && member.uid && member.uid !== oldMember.uid && !weeklyDataOpen) {
      checkWeeklyDataOpenFunc();
    }

     if (soundOn) {
      try {
        if ((!oldMember.supportChatStatus || oldMember.supportChatStatus.read === true) && member.supportChatStatus && member.supportChatStatus.read === false) {
          const playPromise = this.chatInAudio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(_ => {
                // Automatic playback started!
                // Show playing UI.
                console.log("audio played auto");
              })
              .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                console.log("playback prevented");
              });
          }
        }
      } catch (err) {
        console.log('sound err');
      }
    }
  }

  componentWillUnmount() {
    try {
      window.removeEventListener('online', this.handleOnline);
      window.removeEventListener('offline', this.handleOffline);
      window.removeEventListener('resize', this.handleResize);
    } catch (err) {
      console.log('err componentWillUnmount');
    }
  }

  handleResizeJoyride = () => {
    if (this.state.run) {
      const currentIsLandscapeVal = isLandscape();
      const currentIsMobileScreenResolutionVal = isMobileScreenResolution();
  
      if (this.state.isLandscapeVal !== currentIsLandscapeVal || this.state.isMobileScreenResolutionVal !== currentIsMobileScreenResolutionVal) {
        this.closeJoyride();
        this.setState({
          isLandscapeVal: currentIsLandscapeVal,
          isMobileScreenResolutionVal: currentIsMobileScreenResolutionVal
        });
      }
    }
  }

  setTutorialPopoverOpen = (isOpen) => {
    this.setState({tutorialPopoverOpen: isOpen});
  }
  setMainSettingsPopoverOpen = (isOpen) => {
    this.setState({mainSettingsPopoverOpen: isOpen});
  }
  setSupportPopoverOpen = (isOpen) => {
    this.setState({supportPopoverOpen: isOpen});
  }
  handleResize = () => this.setState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  });

  handleOnline = () => {
    this.setState({ isOnline: true });
  }

  handleOffline = () => {
    this.setState({ isOnline: false });
  }

  OpenMobileDropdownModal = () => {
    this.setState({
      mobileDropdownModalOpened: true
    })
  }

  CloseMobiledropdownModal = () => {
    this.setState({
      mobileDropdownModalOpened: false
    })
  }

  toggleFullscreenWrapper = () => {
    const { toggleFullscreen } = this.props;
    toggleFullscreen();
    this.CloseMobiledropdownModal();
  }

  runJoyride = () => {
    this.setState({
      run: true,
    });
  }

  logout = (e, confirmationDisabled) => {
    e.preventDefault();
    const { logout, history, notificationSettings, toggleFullscreen, isFullscreenEnabled } = this.props;

    if (notificationSettings && notificationSettings.quitAccount === false || confirmationDisabled) {
      logout().then(() => {

        if (isFullscreenEnabled) {
          toggleFullscreen();
        }

        history.push('/landing');
      });
    } else {
      this.setState({ quitModal: true });
    }
  }


  requestDeleteUser = (e) => {
    e.preventDefault();
    const { logout, requestDeleteUser, toggleFullscreen, isFullscreenEnabled } = this.props;
    requestDeleteUser().then((res) => {
      if (res.status === "error") {
        console.log('error ')
      } else {

        console.log('succsess')
        setTimeout(() => {
          if (isFullscreenEnabled) {
            toggleFullscreen();
          }

          logout();
        }, 1500);
      }
    })
      .catch((err) => {
        console.log(err);
      });
  }


  closeQuitModal = () => {
    this.setState({ quitModal: false });
  }

  approveQuitModal = () => {
    const { logout, history, toggleFullscreen, isFullscreenEnabled } = this.props;

    this.setState({ quitModal: false });

    logout().then(() => {
      if (isFullscreenEnabled) {
        toggleFullscreen();
      }

      history.push('/landing');
    });
  }

  toggleEditModal = () => {
    this.setState(prevState => ({ editModalOpen: !prevState.editModalOpen }));
  }

  toggleSupport = () => {
    const { supportModalOpen } = this.state;
    const { setSupportAsRead, member, playButtonSound } = this.props;

    if (!supportModalOpen && member.supportChatStatus && !member.supportChatStatus.read) {
      setSupportAsRead();
    }
    if (!supportModalOpen) {
      playButtonSound();

      console.log('ReactGA - Technical Support');

      ReactGA.event({
        category: 'Menu',
        action: 'Technical Support',
      });
    }
    this.setState({ supportModalOpen: !supportModalOpen });
  }

  toggleRules = () => {
    const { playButtonSound, updateLastAction } = this.props;
    const { rulesModalOpen } = this.state;

    playButtonSound();
    updateLastAction();
    this.setState({ rulesModalOpen: !rulesModalOpen });
  }

  UpdateModal = (openModal, modalType, newLevel, insufficientBalanceAmount, insufficientBalanceBet) => {
    this.setState({
      openModal, modalType, newLevel,
    });

    if (modalType === 'noBalanceMenu') {
      this.setState({ insufficientBalanceAmount, insufficientBalanceBet });
    }
  }

  getSteps = () => {
    const { t } = this.props;

    const steps = [
      {
        disableBeacon: true,
        target: '.start-game-button',
        content: t('tutorial.startStep'),
        disableOverlayClose: true,
      },
      {
        disableBeacon: false,
        target: '.top-tab',
        content: t('tutorial.topStep'),
        disableOverlayClose: true,
      },
      {
        disableBeacon: false,
        target: '.my-info-tab',
        content: t('tutorial.myInfoStep'),
        disableOverlayClose: true,
      },
      {
        disableBeacon: false,
        target: '.tournaments-tab',
        content: t('tutorial.tournamentsStep'),
        disableOverlayClose: true,
      },
      {
        disableBeacon: false,
        target: '.shop-tab',
        content: t('tutorial.shopStep'),
        disableOverlayClose: true,
      },
      {
        disableBeacon: false,
        target: '.bonus-tab',
        content: t('tutorial.bonusStep'),
        disableOverlayClose: true,
      },
    ];
    this.setState({ steps });
    return null;
  }

  toggle = (tab) => {
    console.log('toggle', tab);

    const { playButtonSound, updateLastAction, setScreenView, setActiveTabFunc, activeTab } = this.props;
    const { collapsedTab, run } = this.state;
    if (activeTab !== tab) {
      if (tab === constants.MENU_NAVIGATION.top) {
        setScreenView('top');

        ReactGA.event({
          category: 'Menu',
          action: 'Open top page',
        });
      } else if (tab === constants.MENU_NAVIGATION.topMillionaires) {
        setScreenView('top');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Top Millionaires',
        });
      } else if (tab === constants.MENU_NAVIGATION.myInfo) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open my info',
        });
      } else if (tab === constants.MENU_NAVIGATION.bonusPage) {
        setScreenView('bonusPage');

        ReactGA.event({
          category: 'Menu',
          action: 'Open bonus page',
        });
      } else if (tab === constants.MENU_NAVIGATION.buyPage || tab === constants.MENU_NAVIGATION.giftPage || tab === constants.MENU_NAVIGATION.resetBalancePage || tab === constants.MENU_NAVIGATION.moneyPacks) {
        setScreenView('shopPage');

        ReactGA.event({
          category: 'Menu',
          action: 'Open shop',
        });
      } else if (tab === constants.MENU_NAVIGATION.tournaments) {
        setScreenView('tournaments');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Future Tournaments',
        });
      } else if (tab === constants.MENU_NAVIGATION.tournamentsHistory) {
        setScreenView('tournaments');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Tournaments History',
        });
      } else if (tab === constants.MENU_NAVIGATION.futureTournaments) {
        setScreenView('tournaments');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Future Tournaments',
        });
      } else if (tab === constants.MENU_NAVIGATION.ignoredUsers) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open ignored players',
        });
      } else if (tab === constants.MENU_NAVIGATION.archievements) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open achievements',
        });
      } else if (tab === constants.MENU_NAVIGATION.gameHistory) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open My Info Results',
        });
      } else if (tab === constants.MENU_NAVIGATION.powerRankings) {
        setScreenView('powerRankings');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Power Rankings',
        });
      } else if (tab === constants.MENU_NAVIGATION.gameLogs) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Game Logs',
        });
      } else if (tab === constants.MENU_NAVIGATION.weeklyStatus) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Weekly Stats',
        });
      } else if (tab === constants.MENU_NAVIGATION.friends) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Friends',
        });
      } else if (tab === constants.MENU_NAVIGATION.rooms) {
        // setScreenView('rooms');
      }

      try {
        if (!localStorage.getItem('joyrideActive')) { //x
          playButtonSound();
        }
      } catch (err) {
        console.log(err);
      }
      updateLastAction();

      setActiveTabFunc(tab);
      this.setState({
        //activeTab: tab,
        collapsedTab: false,
        activeTabNoContent: '',
        // collapsedTabNoContent: true,
      });
    } else if (!run) {
      this.setState({
        collapsedTab: !collapsedTab,
        activeTabNoContent: '',
      });
    }
  }
  collapseSidebarTab = (value) => {
    this.setState({collapsedTab: value});
  }
  toggleNoContent = (tab) => {
    const { playButtonSound, updateLastAction } = this.props;
    const { activeTabNoContent, collapsedTabNoContent } = this.state;
    if (activeTabNoContent !== tab) {

      // try {
      //   if (!localStorage.getItem('joyrideActive')) {
      //     playButtonSound();
      //   }
      // } catch (err) {
      //   console.log(err);
      // }
      // updateLastAction();

      this.setState({
        activeTabNoContent: tab,
        // collapsedTabNoContent: false,
        collapsedTab: true,
      });
    } else {
      this.setState({
        activeTabNoContent: '',
        // collapsedTabNoContent: !collapsedTabNoContent,
      });
    }
  }

  toggleBottomBarTab = (type, cookiesFrom) => {
    const { playButtonSound, updateLastAction } = this.props;

    playButtonSound();
    updateLastAction();

    if (type === 'moneyHistory') {
      const { fetchBalanceHistory, member } = this.props;
      const { balanceHistoryModalOpen } = this.state;

      if (member && member.offset) {
        fetchBalanceHistory('today', member.offset);
      } else {
        fetchBalanceHistory('today', 0);
      }

      if (!balanceHistoryModalOpen) {
        ReactGA.event({
          category: 'Menu',
          action: 'Open balance history',
        });
      }
      this.setState(prevState => ({
        balanceHistoryModalOpen: !prevState.balanceHistoryModalOpen,
      }));
    } else if (type === 'pointsHistory') {
      const { fetchPointsHistory, member } = this.props;
      const { pointsHistoryModalOpen } = this.state;

      if (member && member.offset) {
        fetchPointsHistory('today', member.offset);
      } else {
        fetchPointsHistory('today', 0);
      }

      console.log('ReactGA - points history', pointsHistoryModalOpen);

      if (!pointsHistoryModalOpen) {
        ReactGA.event({
          category: 'Menu',
          action: 'Open points history',
        });
      }
      this.setState(prevState => ({
        pointsHistoryModalOpen: !prevState.pointsHistoryModalOpen,
      }));
    } else if (type === 'bannedUsers') {
      const { bannedUsersModalOpen } = this.state;

      this.setState(prevState => ({
        bannedUsersModalOpen: !prevState.bannedUsersModalOpen,
      }));

      console.log('ReactGA - points history', bannedUsersModalOpen);

      if (!bannedUsersModalOpen) {
        ReactGA.event({
          category: 'Menu',
          action: 'Open banned users',
        });
      }
    } else if (type === 'helpPage') {
      const { helpPageOpen } = this.state;

      if (!helpPageOpen) {
        ReactGA.event({
          category: 'Menu',
          action: 'Open help page',
        });
      }

      this.setState(prevState => ({
        helpPageOpen: !prevState.helpPageOpen,
        termsOfUseOpen: false,
      }));
    } else if (type === 'aboutPage') {
      const { aboutModalOpen } = this.state;

      if (!aboutModalOpen) {
        ReactGA.event({
          category: 'Menu',
          action: 'Open About Us',
        });
      }

      this.setState(prevState => ({
        aboutModalOpen: !prevState.aboutModalOpen,
      }));
    } else if (type === 'termsOfUse' || type === constants.FOOTER_NAVIGATION_LINK.termsOfUse) {
      const { termsOfUseOpen } = this.state;

      if (!termsOfUseOpen) {
        ReactGA.event({
          category: 'Menu',
          action: 'Open terms of use page',
        });
      }
      if (cookiesFrom) {
        this.setState({
          cookiesFrom: constants.COOKIES_TERMS_OPEN.login,
        });
      } else {
        this.setState({
          cookiesFrom: '',
        });
      }
      this.setState(prevState => ({
        termsOfUseOpen: !prevState.termsOfUseOpen,
        helpPageOpen: false,
      }));
    } else if (type === 'tutorial') {
      // this.getSteps();

      ReactGA.event({
        category: 'Menu',
        action: 'Open guide',
      });

      this.setState({
        run: true,
      });
    }
  }

  closeModal = () => {
    const { resetErrorNotif } = this.props;
    const { modalType } = this.state;
    if (modalType === 'alreadyRegisteredAnotherTournament' || modalType === 'tournamentMaxPlayers'
      || modalType === 'tournamentAlreadyRegistered' || modalType === 'noBalanceMenu' || modalType === 'proRoomMenu' || modalType === 'emailNotVerified'
      || modalType === 'proBetMenu' || modalType === 'noBalanceTournament' || modalType === 'youIgnoredPlayer' || modalType === 'playerIgnoredYou') {
      resetErrorNotif();
    }
    this.setState({
      openModal: false, modalType: '', insufficientBalanceAmount: null, insufficientBalanceBet: null,
    });
  }

  handleClickStart = (e) => {
    const { playButtonSound } = this.props;
    e.preventDefault();

    playButtonSound();

    ReactGA.event({
      category: 'Menu',
      action: 'Open guide',
    });
    console.log("run joyride ");
    this.setState({
      run: true,
    });
  };

  closeJoyride = () => {
    const { disableTutorial, member } = this.props;
    this.setState({
      run: false,
    });
    try {
      if (localStorage.getItem('allTours')) { //x
        localStorage.removeItem('allTours'); //x
      }
    } catch (err) {
      console.log(err);
    }
    if (member && !member.tutorialShown) {
      disableTutorial();
    }
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { disableTutorial, member } = this.props;
    const { status, action } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === 'close') {
      this.setState({ run: false });

      if (!member || !member.tutorialShown) {
        disableTutorial();
      }
    }
  };

  closeFirstTime = () => {
    const { disableFirstTimeNotif, member } = this.props;

    if (!member || !member.firstTimeModal) {
      disableFirstTimeNotif();
      if (document.getElementById('top-tab') && document.getElementById('top-tab').isSameNode) {
        // this.getSteps();
        this.setState({
          run: true,
        });
      }
    }
  }

  setAchievementsNotification = (val) => {
    const { claimAchieveProcess, openAchievementsModal } = this.state;
    this.setState({
      achievementsNotification: val,
    });

    if (!claimAchieveProcess) {
      console.log({ claimAchieveProcess }, { openAchievementsModal }, "claimProcess")
      this.setState({
        openAchievementsModal: true,
      });
    }
  }

  showUserSettingsModal = () => {
    const { showUserSettingsModal } = this.state;

    if (!showUserSettingsModal) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open Settings',
      });
    }

    this.setState(prevState => ({ showUserSettingsModal: !prevState.showUserSettingsModal }));
  }

  //  toggleShowTournamentTutorial = () => {
  //    this.setState(prevState => ({ showTournamentTutorial: !prevState.showTournamentTutorial }));
  //  }


  updateProfile = (data) => {
    const { updateProfile, t } = this.props;

    console.log('eeee updateProfile', data);

    return new Promise(async (resolve, reject) => {
      console.log('e1: ')
      updateProfile(data).then((res) => {
        console.log('2')
        console.log(res, "=================");
        if (res.status === "error" && res.message == 'maxNameChangeLimit') {
          // this.setState({loading: false, error:  t('errorAccountRenameCount')});
        } else if (data && data.changeEmail && res && res.status === 'success' && res.emailChanged) {
          console.log('email  changed')
        } else {
          console.log('success')
        }
      }).catch(err => {
        console.log(err);
        let { message } = err;
        let text;
        if (message === 'auth/requires-recent-login') {
          //  message = 'Lai nomainītu epastu nepieciešama nesena ielogošanās';
          text = t('reAuth');
        } else if (message === 'auth/email-already-in-use') {
          //  message = 'Epasta adrese jau aizņemta';
          text = t('emailUsed');
        } else {
          text = t(message);
        }
      });
    })
  }

  userAvatarFileUpload = (data) => {
    const { updateAvatarFileUpload } = this.props;

    return updateAvatarFileUpload(data).catch((err) => {
      console.log(err);
    });
  }

  toggleStripeLoading = (stripeLoading) => {
    this.setState({
      stripeLoading,
    });
  };

  toggleVerifySuccessModal = () => {
    const { history } = this.props;
    history.push('/');
    this.setState({
      toggleVerifySuccess: false,
    });
  }

  closeCookiesModal = () => {
    this.setState({
      cookiesModalOpen: false,
    });
  }

  handleCookiesSet = () => {
    try {
      if (!localStorage.getItem('zoleCookies')) { //x
        localStorage.setItem('zoleCookies', JSON.stringify(true)); //x
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({
      cookiesModalOpen: false,
    });
  }

  toggleHolidayModal = (flag) => {
    this.setState({
      holidayModalOpen: flag,
    });
  };

  toggleBonusModal = (flag) => {
    this.setState({
      bonusModalOpen: flag,
    });
  }

  closeAchievementsModal = () => {
    const { userAchievements, claimedAchievements, claimAchievementFunc, gamesPlayed, gamesWon, } = this.props;
    let userAchieves = {};
    userAchieves = {
      ...userAchievements,
      gamesPlayed,
      gamesWon,
    }
    this.setState({
      claimAchieveProcess: true,
      openAchievementsModal: false,
      roomEndedModalBlock: true,
    })
    map(userAchieves, async (item, key) => {
      if (size(constants.UNIQUE_ACHIEVEMENT_DATA[key]) > 0) {
        let reward = 0;

        if (item && !claimedAchievements[key]) {
          reward = constants.UNIQUE_ACHIEVEMENT_DATA[key].reward;
        }

        if (reward > 0) {
          await claimAchievementFunc(key);
        }
      } else if (size(constants.ACHIEVEMENT_DATA[key]) > 0) {
        let reward = 0;
        const claimedAchievementsVal = claimedAchievements[key] || 0;
        constants.ACHIEVEMENT_DATA[key].borders.map((borderItem, index) => {
          // console.log(key, item, borderItem, claimedAchievementsVal, index, "claimReward");
          if (item >= borderItem && claimedAchievementsVal <= index) {
            reward += constants.ACHIEVEMENT_DATA[key].rewards[index];
          }
          return null;
        });
        if (reward > 0) {
          await claimAchievementFunc(key);
        }
      }
    });
  }

  openAchievements = () => {
    this.closeAchievementsModal();
    this.toggle(constants.MENU_NAVIGATION.archievements);
  }

  toggleBuyModal = () => {
    this.setState({ buyModal: !this.state.buyModal });
  }

  togglePaymentErrorModal = (paymentError) => {
    this.setState({
      paymentErrorModal: !this.state.paymentErrorModal,
      paymentError
    });
  }

  buyMoney = (prod) => {
    const {
      initFBPay, fbCallback, socProvider, initDraugiemPay, initInboxPay, toggleTransactionSuccess, toggleStripeLoading, isFullscreen, isFullscreenEnabled, toggleFullscreen,
    } = this.props;

    if (config.isInAppFrame() && isFullscreenEnabled) {
      toggleFullscreen();
    }

    if (!config.isInAppFrame()) {
      this.playButtonSound();

      this.setState({ selectedProduct: constants.BUY_PAGE_PRODUCTS[prod], selectedProductId: prod });
      this.toggleBuyModal();
    } else if (socProvider === constants.SOC_PROVIDERS.facebook) { // In App Iframe (fb/dra)
      this.playButtonSound();

      initFBPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            if (window.FB && socProvider === constants.SOC_PROVIDERS.facebook) {
              window.FB.ui({
                method: 'pay',
                action: 'purchaseitem',
                product,
                quantity: 1, // optional, defaults to 1
                request_id: res.data.data.token, // optional, must be unique for each payment
              },
                (resp) => {
                  fbCallback(resp);
                  return null;
                });
            } else {
              //  console.log('no FB.ui or not facebook provider');
            }
          } else {
            //  console.log('error with product');
          }
        }
      });
    } else if (socProvider === constants.SOC_PROVIDERS.draugiem) {
      this.playButtonSound();
      initDraugiemPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            if (window.draugiemWindowOpen && socProvider === constants.SOC_PROVIDERS.draugiem) {
              window.draugiemWindowOpen(product, 350, 400);
            } else {
              //  console.log('no draugiemWindowOpen or not draugiem provider');
            }
          } else {
            //    console.log('error with product');
          }
        }
      });
    } else if (socProvider === constants.SOC_PROVIDERS.inbox) {
      this.playButtonSound();
      initInboxPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            this.setState({ showInboxPayment: true, inboxIframeLink: product });
          } else {
            //  console.log('error with product');
          }
        }
      });
    }
  }

  pay = () => {
    const { selectedPaymentOption, selectedProduct, selectedProductId, checkedTerms } = this.state;
    const { initEveryPay, t, i18n } = this.props;

    if (!checkedTerms) {
      this.togglePaymentErrorModal(t('paymentType.acceptTerms'));
    } else if (![constants.PAYMENT_TYPES.paypal.id, constants.PAYMENT_TYPES.textMessage.id].includes(selectedPaymentOption.id)) {

      this.toggleStripeLoading(true);

      initEveryPay(selectedProduct, selectedPaymentOption, selectedProductId, i18n.language).then((res) => {
        const { payment_link } = res.data;
        if (payment_link) {
          window.location.href = payment_link;
        }
      }).catch(error => {
        this.toggleStripeLoading(false);
        throw error;
      });
    } else {
      this.togglePaymentErrorModal(t('paymentType.notAvailable'));
    }
  }

  playButtonSound = () => {
    const { soundOn } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
  }

  onPaymentOptionClick = (option) => {
    this.setState({
      selectedPaymentOption: option,
    });
  };

  toggleCheckedTerms = () => {
    this.setState(prevState => ({
      checkedTerms: !prevState.checkedTerms,
    }));
  };

  togglePwOption = () => {
    const { pwOption, setPwOptionFunc } = this.props;
    setPwOptionFunc(!pwOption);
  }

  toggleIgnoredSwitchChecked = () => {
    const { ignoredSwitchChecked, setIgnoredSwitchCheckedFunc } = this.props;
    setIgnoredSwitchCheckedFunc(!ignoredSwitchChecked);
  }

  toggleHolidayDebuggerModal = () => {
    const { openHolidayDebugger } = this.setState;
    if (openHolidayDebugger) {
      this.setState({
        selectedTestHoliday: null,
      });
    }
    this.setState(prevState => ({
      openHolidayDebugger: !prevState.openHolidayDebugger,
    }));
  };

  handleSelectTestHoliday = (id) => {
    this.setState({
      selectedTestHoliday: filter(constants.HOLIDAY_TYPES, item => item.id === id),
      openTestHolidayType: false,
    });
  }

  setHolidayTestModal = () => {
    const { selectedTestHoliday } = this.state;

    if (selectedTestHoliday[0].type === 'triggerModal' && selectedTestHoliday[0].value === 'changeLog') {
      this.setState({
        openChangeLogVersionModal: true,
      })
    } else if (selectedTestHoliday[0].type === 'triggerModal' && selectedTestHoliday[0].value === 'newHelp') {
      this.setState({
        openNewHelpModal: true,
      })
    } else {
      this.setState({
        holidayTest: true,
      });  
    }
    this.setState({
      openHolidayDebugger: false,
    });
  };

  cancelHolidayTest = () => {
    this.setState({
      holidayTest: false,
    });
  };

  toggleTestHolidayType = () => {
    this.setState(prevState => ({
      openTestHolidayType: !prevState.openTestHolidayType,
    }));
  }

  closeTriggerChangeLogModal = () => {
    this.setState({
      openChangeLogVersionModal: false,
    })
  }

  returnToAdmin = () => {
    const { member, returnToAdmin, adminReturnToken, history, toggleNewDesign, toggleScreenMode, i18n } = this.props;

    console.log('call returnToAdmin', adminReturnToken);

    if (adminReturnToken) {
      returnToAdmin(adminReturnToken, toggleNewDesign, toggleScreenMode, i18n, member.uid).then((res) => {
        console.log('returnToAdmin res', res);

        if (res === 'success') {
          history.push('/admin');
        }
      })
    }
  }

  toggleNewHelpModal = () => {
    this.setState(prevState => ({
      openNewHelpModal: !prevState.openNewHelpModal,
    }));
  }

  setLastPlayerChangeModalClosed = (val) => {
    this.setState({ lastPlayerChangeModalClosed: val });
  }

  render() {
    const {
      member,
      loading,
      createRoom,
      loadingProgress,
      joinRoom,
      t,
      i18n,
      resetErrorNotif,
      ignoredMessageName,
      ignoredMessageUid,
      privateRoomPassword,
      showPrivateRoomPassword,
      closePrivateRoomPassword,
      playButtonSound,
      hasActiveRegistration,
      myTournamentsData,
      isFullscreen,
      isFullscreenEnabled,
      toggleFullscreen,
      notificationSettings,
      toggleNewDesign,
      weeklyDataOpen,
      closeWeeklyData,
      screenMode,
      toggleScreenMode,
      fontMode,
      toggleFont,
      closeLevelNotification,
      resetFilterRooms,
      roomEnded,
      tournamentRoomEnded,
      balance,
      setRoomEndedFunction,
      socProvider,
      resetError,
      isEventOption,
      toggleEvent,
      setSupportAsRead,
      setScreenView,
      role,
      designPlatform,
      platform,
      campaignData,
      activeTab,
      setActiveTabFunc,
      adminReturnToken,
      pwOption,
      ignoredSwitchChecked,
    } = this.props;

    const {
      collapsedTab,
      activeTabNoContent,
      openModal,
      modalType,
      newLevel,
      steps,
      supportModalOpen,
      aboutModalOpen,
      run,
      balanceHistoryModalOpen,
      pointsHistoryModalOpen,
      bannedUsersModalOpen,
      editModalOpen,
      helpPageOpen,
      termsOfUseOpen,
      insufficientBalanceAmount,
      insufficientBalanceBet,
      achievementsNotification,
      showUserSettingsModal,
      quitModal,
      //  showTournamentTutorial,
      stripeLoading,
      toggleVerifySuccess,
      cookiesModalOpen,
      cookiesFrom,
      holidayModalOpen,
      bonusModalOpen,
      openAchievementsModal,
      mobileDropdownModalOpened,
      buyModal,
      paymentErrorModal,
      selectedProduct,
      selectedPaymentOption,
      isOnline,
      checkedTerms,
      paymentError,
      windowHeight,
      windowWidth,
      roomEndedModalBlock,
      mainSettingsPopoverOpen,
      supportPopoverOpen,
      tutorialPopoverOpen,
      openHolidayDebugger,
      selectedTestHoliday,
      holidayTest,
      openTestHolidayType,
      openChangeLogVersionModal,
      devMode,
      openNewHelpModal,
      lastPlayerChangeModalClosed,
    } = this.state;

    const roomEndedModalOpen = roomEnded && balance < 200 && !roomEndedModalBlock && !(achievementsNotification && openAchievementsModal && !weeklyDataOpen && member && member.newVersion >= constants.NEW_VERSION);
    if (loading || (!member.uid)) {
      return (
        <Fragment>
          <LoadingPage isFullscreen={isFullscreen} loading={loading} loadingProgress={loadingProgress} screenMode={screenMode} devMode={devMode} />
        </Fragment>
      );
    }

    if (stripeLoading) {
      return (
        <Fragment>
          <Helmet>
            <title>
              Zole - {t('home.loading')}
            </title>
          </Helmet>
          <div className='stripe-loading'>
            <Spinner color={screenMode} style={{ width: '5rem', height: '5rem' }} />
            <Label className='stripe-loading-content'>{t('home.paymentSystemLoading')}</Label>
          </div>
        </Fragment>
      );
    }

    if (member && member.blocked) {
      return (
        <BlockedPage
          isFullscreen={isFullscreen}
          isFullscreenEnabled={isFullscreenEnabled}
          toggleStripeLoading={this.toggleStripeLoading}
          screenMode={screenMode}
          toggleFullscreen={toggleFullscreen}
        />
      );
    }
    // console.log(weeklyDataOpen, "weeklyDataOpen=")
    return (
      <ErrorBoundary
        fallbackRender={fallbackRender}
      >
      <Fragment>
        <Notification
          openModal={openModal}
          modalType={modalType}
          ignoredMessageName={ignoredMessageName}
          ignoredMessageUid={ignoredMessageUid}
          closeModal={this.closeModal}
          resetError={resetError}
          resetErrorNotif={resetErrorNotif}
          newLevel={newLevel}
          switchTabs={this.toggle}
          insufficientBalanceAmount={insufficientBalanceAmount}
          insufficientBalanceBet={insufficientBalanceBet}
          closeLevelNotification={closeLevelNotification}
              />

        <HolidayModals run={run} toggleHolidayModal={this.toggleHolidayModal} holidayTest={holidayTest} selectedTestHoliday={selectedTestHoliday[0]} cancelHolidayTest={this.cancelHolidayTest} />
        <BonusModals openModal={openModal} holidayModalOpen={holidayModalOpen} toggleBonusModal={this.toggleBonusModal} />
        {windowWidth > 1280 && (
          <EventModals
            switchTabs={this.toggle}
            isEventOption={isEventOption}
            toggleEvent={toggleEvent}
            designPlatform={designPlatform}
            toggleNewDesign={toggleNewDesign}
          />
        )}

        {windowWidth <= 1280 && (
          <EventMobileModals
            switchTabs={this.toggle}
            isEventOption={isEventOption}
            toggleEvent={toggleEvent}
            designPlatform={designPlatform}
            toggleNewDesign={toggleNewDesign}
          />
        )}
        {!cookiesModalOpen && (
          <Feedback screenMode={screenMode} run={run} />
        )}

          {member && member.newVersion >= constants.NEW_VERSION && !adminReturnToken && !openModal && !holidayModalOpen && !bonusModalOpen && !weeklyDataOpen && !toggleVerifySuccess && (!openAchievementsModal || !achievementsNotification) && (
            /*  <ErrorBoundary
            fallbackRender={fallbackRender5}
            > */
          <Hints screenMode={screenMode} fontMode={fontMode} />
              /*  </ErrorBoundary> */
        )}

          <Cookies screenMode={screenMode} cookiesModalOpen={cookiesModalOpen && !adminReturnToken && !config.isInAppFrame()} closeCookiesModal={this.closeCookiesModal} handleCookiesSet={this.handleCookiesSet} toggleHomeContainer={this.toggleBottomBarTab} run={run} />
        {member && member.newVersion >= constants.NEW_VERSION && !openModal && !holidayModalOpen && !bonusModalOpen && weeklyDataOpen ? (
          <>
            <CustomModal
              isOpen={weeklyDataOpen}
              onButtonClose={closeWeeklyData}
              size="lg"
              title={t('myInfo.weeklyStatus')}
              addClassName={"weekly-status-home-modal"}
              body={<WeeklyStatusHome closeWeeklyData={closeWeeklyData} />}
            />
          </>
          ) : (null)}
        <div className={`top-row top-row-${screenMode}`}>
          <div className="logo-wrapper">
            <a className="logo" target="_blank" rel="noopener noreferrer" href="https://spelezoli.lv/" alt="ZOLE" />
          </div>
          <Media src={coinImg} className="coin-img"/>
          <div className={`coin-text coin-text-${screenMode}`}>{member.balance}</div>

          <Media src={ratingImg} className="rating-img"/>
          <div className={`rating-text rating-text-${screenMode}`}>{member.rating}</div>

          <div className='mobile-top-setting d-none'>
            <a href="#" className='tutorial-link' onClick={(e) => this.handleClickStart(e)} id="tutorial-popover" onMouseEnter={() => this.setTutorialPopoverOpen(true)} onMouseLeave={() => this.setTutorialPopoverOpen(false)}>
              <Media src={humanSmallImg} alt='X' className='tutorial-link-image' />
              <IconPopover text={t('popovers.tutorial')} placement="bottom" targetId="tutorial-popover" popoverOpen={tutorialPopoverOpen} />
            </a>
              {(role === 'admin' || role === 'tester') && (
                <Media alt="Holiday Debugger" className='debugger-holiday debugger-holiday-new-mobile d-none' src={debuggerImg} width={32} height={32} onClick={() => this.toggleHolidayDebuggerModal()} />
              )}
            <div
              className='chat-link'
              id="contact-support-popover"
              onMouseEnter={() => this.setSupportPopoverOpen(true)}
              onMouseLeave={() => this.setSupportPopoverOpen(false)}
              onClick={() => this.toggleSupport()}
            >
              <Media src={(screenMode === constants.SCREEN_MODE.light) ? chatLinkLightImg : chatLinkImg} alt='X' className='chat-link-image' />
              <IconPopover text={t('popovers.contactSupport')} placement="bottom" targetId="contact-support-popover" popoverOpen={supportPopoverOpen} />
            </div>
            <div
              className={`dropdown-menu-link ${member.supportChatStatus && member.supportChatStatus.read === false ? 'incoming-mWeb' : ''}`}
              onClick={this.OpenMobileDropdownModal}
              id="main-settings-popover"
              onMouseEnter={() => this.setMainSettingsPopoverOpen(true)}
              onMouseLeave={() => this.setMainSettingsPopoverOpen(false)}
            >
              <AiOutlineMore />
              <IconPopover text={t('popovers.mainSettings')} placement="bottom" targetId="main-settings-popover" popoverOpen={mainSettingsPopoverOpen} />
            </div>
          </div>
        </div>
        <OnlineCount />
        {showUserSettingsModal ? (
          <>
            <CustomModal
              isOpen={showUserSettingsModal}
              toggle={this.showUserSettingsModal}
              size="xl"
              title={t('menu.settings')}
              addClassName={`user-settings-modal ${'user-settings-modal-in-app-frame'}`}
              // footer={(
              //   <Button color="secondary" onClick={this.showUserSettingsModal}>
              //     {t('common.save')}
              //   </Button>
              // )}
              footerClose
              body={
                <UserSettings
                  language={i18n.language}
                  toggleNewDesign={toggleNewDesign}
                  screenMode={screenMode}
                  showUserSettingsModal={this.showUserSettingsModal}
                  isLandscapeMobile={windowWidth <= 1280 && windowWidth > windowHeight}
                  isPortraitMobile={windowWidth <= 1280 && windowWidth <= windowHeight}
                  i18n={i18n}
                />
              }
            />
          </>
            ) : (null)}
          {member && member.newVersion >= constants.NEW_VERSION && !adminReturnToken && !openModal && !holidayModalOpen && !bonusModalOpen && !weeklyDataOpen && !toggleVerifySuccess && (!openAchievementsModal || !achievementsNotification) && windowWidth > 1280 && (
          <JoyrideComponent run={run} toggle={this.toggle} closeJoyride={this.closeJoyride} tutorialBonus={member.tutorialBonus || false} />
        )}
        <>
          { activeTab && constants.MY_INFO_TABS.includes(activeTab) &&
            <Row className="layout-mobile-subheader">
              <MyInfoSubHeader changeTab={this.toggle} activeTab={activeTab} />
            </Row>
          }
          { activeTab && constants.TOURNAMENTS_TABS.includes(activeTab) &&
            <Row className="layout-mobile-subheader">
              <TournamentsSubHeader changeTab={this.toggle} activeTab={activeTab} screenMode={screenMode} />
            </Row>
          }
          <div className={`layout layout-${screenMode}`}>
            <LayoutHeader t={t} activeTab={activeTab} toggle={this.toggle} hasActiveRegistration={hasActiveRegistration} achievementsNotification={achievementsNotification} showUserSettingsModal={this.showUserSettingsModal} />
            

            <TabContent className="layout-body-content" activeTab={activeTab}>
              <div className="joyride-enter-room-zone" />

              <TabPane className="layout-body-pane new-rooms-table-section" tabId="1">
                  {/* activeTab && activeTab === '1' && */ windowWidth > 1280 && (
                  <RoomsTable
                      privateRoomPassword={privateRoomPassword}
                      joinRoom={joinRoom}
                      isFullscreen={isFullscreen}
                      createRoom={createRoom}
                      toggle={this.toggle}
                      screenMode={screenMode}
                      fontMode={fontMode}
                      resetFilterRooms={resetFilterRooms}
                      windowWidth={windowWidth}
                      setScreenView={setScreenView}
                      campaignData={campaignData}
                      devMode={devMode}
                      lastPlayerChangeModalClosed={lastPlayerChangeModalClosed}
                      setLastPlayerChangeModalClosed={this.setLastPlayerChangeModalClosed}
                  />
                )}
              </TabPane>
              <TabPane className="layout-body-pane new-top-table-section" tabId="2">
                {activeTab && activeTab === '2' && (
                  <TopPage toggle={this.toggle} notificationSettings={notificationSettings} screenMode={screenMode} />
                )}
              </TabPane>
              <TabPane className="main-tab-pane new-power-rankings-section" tabId={activeTab}>
                {activeTab && (constants.POWER_RANKINGS_TABS).includes(activeTab) && windowWidth > 1280 && (
                  <PowerRankings parentActiveTab={activeTab} parentChangeTab={this.toggle} screenMode={screenMode} checked={pwOption} togglePWOption={this.togglePwOption} />
                )}
              </TabPane>
              <TabPane className="layout-body-pane new-my-info-section" tabId="3">
                {activeTab && activeTab === '3' && windowWidth > 1280 && (
                  <MyInfo
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    notificationSettings={notificationSettings}
                    screenMode={screenMode}
                    fontMode={fontMode}
                  />
                )}
              </TabPane>
              <TabPane className="layout-body-pane" tabId="4">
                <BonusPage
                  activeTab={activeTab}
                  changeTab={this.toggle}
                  //  doRender={!!(activeTab && activeTab === '4')}
                  isFullscreen={isFullscreen}
                  screenMode={screenMode}

                />
              </TabPane>
              {activeTab && constants.BUY_PAGE_TABS.includes(activeTab) && windowWidth > 1280 &&
                <TabPane className="layout-body-pane" tabId={activeTab}>
                  <BuyPage
                    toggleTerms={() => this.toggleBottomBarTab('termsOfUse')}
                    toggleAboutUs={() => this.toggleBottomBarTab('aboutPage')}
                    screenMode={screenMode}
                    toggleStripeLoading={this.toggleStripeLoading}
                    switchToggle={this.toggle}
                    parentActiveTab={activeTab}
                    isFullscreen={isFullscreen}
                    isFullscreenEnabled={isFullscreenEnabled}
                    toggleFullscreen={toggleFullscreen}
                  />
                </TabPane>
              }

              {activeTab && (activeTab === constants.MENU_NAVIGATION.tournaments || activeTab === constants.MENU_NAVIGATION.futureTournaments || activeTab === constants.MENU_NAVIGATION.tournamentsHistory) && (
                <TabPane className="layout-body-pane" tabId={activeTab}>
                  <TournamentsHeader
                    activeTab={activeTab}
                    changeTab={this.toggle}
                    screenMode={screenMode}
                  />
                  {activeTab === constants.MENU_NAVIGATION.tournaments &&
                    <Tournaments
                      changeTab={this.toggle}
                      screenMode={screenMode}
                    />
                  }
                  {activeTab === constants.MENU_NAVIGATION.futureTournaments &&
                    <TournamentsPlan
                      changeTab={this.toggle}
                      screenMode={screenMode}
                      openModalNotif={openModal}
                      modalTypeNotif={modalType}
                      updateModalNotif={this.UpdateModal}
                    />
                  }
                  {activeTab === constants.MENU_NAVIGATION.tournamentsHistory &&
                    <TournamentsHistory
                      changeTab={this.toggle}
                      screenMode={screenMode}
                    />
                  }

                </TabPane>
              )}

              {(myTournamentsData && myTournamentsData.tournamentId) ? (
                <TournamentNotification screenMode={screenMode} />
              ) : (null)}

              {activeTab && activeTab === '9' && windowWidth > 1280 && (
                <TabPane className="layout-body-pane" tabId="9">
                  <Friends
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    screenMode={screenMode}
                  />
                </TabPane>
              )}
              {activeTab && activeTab === '10' && windowWidth > 1280 && (
                <TabPane className="layout-body-pane" tabId="10">
                  <IgnoredUsers
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    screenMode={screenMode}
                    fontMode={fontMode}
                    ignoredSwitchChecked={ignoredSwitchChecked}
                    toggleIgnoredSwitchChecked={this.toggleIgnoredSwitchChecked}
                  />
                </TabPane>
              )}
              {windowWidth > 1280 && (
                <TabPane className="layout-body-pane" tabId="11">
                  <Achievements
                    activeTab={activeTab}
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    setAchievementsNotification={this.setAchievementsNotification}
                    screenMode={screenMode}
                  />
                </TabPane>
              )}
              {activeTab && activeTab === '12' && windowWidth > 1280 && (
                <TabPane className="layout-body-pane" tabId="12">
                  <GamesHistory
                    activeTab={activeTab}
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    isFullscreen={isFullscreen}
                    isFullscreenEnabled={isFullscreenEnabled}
                    innerHeight={window.innerHeight}
                    windowHeight={window.height}
                    screenMode={screenMode}
                  />
                </TabPane>
              )}
              {activeTab && activeTab === constants.MENU_NAVIGATION.weeklyStatus && windowWidth > 1280 && (
                <TabPane className="layout-body-pane" tabId={constants.MENU_NAVIGATION.weeklyStatus}>
                  <WeeklyStatus
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    screenMode={screenMode}
                  />
                </TabPane>
              )}
              {activeTab && activeTab === constants.MENU_NAVIGATION.gameLogs && windowWidth > 1280 && (
                <TabPane className="layout-body-pane" tabId={constants.MENU_NAVIGATION.gameLogs}>
                  <GameLogs
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    screenMode={screenMode}
                  />
                </TabPane>
              )}
            </TabContent>
            {
              windowWidth > 1280 && (
                <LayoutFooter screenMode={screenMode} toggleScreenMode={toggleScreenMode} changeTab={this.toggle} t={t} requestDeleteUser={this.requestDeleteUser} updateProfile={this.updateProfile} userAvatarFileUpload={this.userAvatarFileUpload} />
              )
            }
          </div>

          <div className={`layout-mobile layout-mobile-${screenMode} d-none`}>
            <LayoutMobileHeader screenMode={screenMode} t={t} activeTab={activeTab} toggle={this.toggle} hasActiveRegistration={hasActiveRegistration} achievementsNotification={achievementsNotification} showUserSettingsModal={this.showUserSettingsModal} />
            { windowWidth <= 1280 &&
              <LandScapeLayoutMobileHeader
                openInfo={this.openInfo}
                openCreate={this.openCreate}
                toggleShowTournamentTutorial={() => { }}
                openFilter={this.openFilter}
                // disableCreate={(activeTournament || joinRoomClicked || joinedRoom)}
                screenMode={screenMode}
                fontMode={fontMode}
                toggleFont={toggleFont}
                t={t}
                activeTab={activeTab}
                collapsedTab={collapsedTab}
                activeTabNoContent={activeTabNoContent}
                // collapsedTabNoContent={collapsedTabNoContent}
                toggle={this.toggle}
                toggleNoContent={this.toggleNoContent}
                hasActiveRegistration={hasActiveRegistration}
                achievementsNotification={achievementsNotification}
                handleClickStart={this.handleClickStart}
                OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                showUserSettingsModal={this.showUserSettingsModal}
                toggleBottomBarTab={this.toggleBottomBarTab}
                joyrideActive={run}
                i18n={i18n}
                pwOption={pwOption}
                toggleSupport={this.toggleSupport}
              />
            }

            <TabContent className="layout-mobile-body-content" activeTab={activeTab}>
              <div className="joyride-enter-room-zone-mobile" />

              <TabPane className="layout-mobile-body-pane new-rooms-table-section" tabId="1">
                {(/* activeTab && activeTab === '1' && */ windowWidth <= 1280) && (
                  <RoomsMobileTable
                      privateRoomPassword={privateRoomPassword}
                      joinRoom={joinRoom}
                      isFullscreen={isFullscreen}
                      createRoom={createRoom}
                      toggle={this.toggle}
                      screenMode={screenMode}
                      fontMode={fontMode}
                      resetFilterRooms={resetFilterRooms}
                      t={t}
                      activeTab={activeTab}
                      setScreenView={setScreenView}
                      hasActiveRegistration={hasActiveRegistration}
                      achievementsNotification={achievementsNotification}
                      showUserSettingsModal={this.showUserSettingsModal}
                      handleClickStart={this.handleClickStart}
                      OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                      campaignData={campaignData}
                      devMode={devMode}
                      lastPlayerChangeModalClosed={lastPlayerChangeModalClosed}
                      setLastPlayerChangeModalClosed={this.setLastPlayerChangeModalClosed}
                  />
                )}

              </TabPane>
              <TabPane className="layout-mobile-body-pane new-top-table-section" tabId="2">
                {activeTab && activeTab === '2' && windowWidth <= 1280 && (
                  <TopPageMobile
                    toggle={this.toggle}
                    notificationSettings={notificationSettings}
                    screenMode={screenMode}
                    parentTab={activeTab}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                  />
                )}
              </TabPane>
              {(constants.POWER_RANKINGS_TABS).includes(activeTab) &&
              <TabPane className="layout-mobile-body-pane new-power-rankings-section" tabId={activeTab}>
                {activeTab && (constants.POWER_RANKINGS_TABS).includes(activeTab) && windowWidth <= 1280 && (
                  <>
                    <PowerRankingsMobile
                      activeTab={activeTab}
                      parentChangeTab={this.toggle}
                      parentActiveTab={activeTab}
                      screenMode={screenMode}
                      showUserSettingsModal={this.showUserSettingsModal}
                      handleClickStart={this.handleClickStart}
                      OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                      checked={pwOption}
                      togglePWOption={this.togglePwOption}
                    />
                  </>
                )}
              </TabPane>
              }
              <TabPane className="layout-mobile-body-pane new-my-info-section" tabId="3">
                {activeTab && activeTab === '3' && windowWidth <= 1280 && (
                  <MyInfoMobile
                    activeTab={activeTab}
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    notificationSettings={notificationSettings}
                    screenMode={screenMode}
                    fontMode={fontMode}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                    toggleBottomBarTab={this.toggleBottomBarTab}
                  />
                )}
              </TabPane>
              {activeTab && activeTab === '12' && windowWidth <= 1280 && (
                <TabPane className="layout-mobile-body-pane new-my-info-section" tabId="12">
                  <GamesHistoryMobile
                    activeTab={activeTab}
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    isFullscreen={isFullscreen}
                    isFullscreenEnabled={isFullscreenEnabled}
                    innerHeight={windowWidth}
                    windowHeight={windowHeight}
                    screenMode={screenMode}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                  />
                </TabPane>
              )}
              <TabPane className="layout-mobile-body-pane" tabId="4">
                <BonusMobilePage
                  activeTab={activeTab}
                  changeTab={this.toggle}
                  //  doRender={!!(activeTab && activeTab === '4')}
                  isFullscreen={isFullscreen}
                  screenMode={screenMode}
                  toggle={this.toggle}
                  hasActiveRegistration={hasActiveRegistration}
                  achievementsNotification={achievementsNotification}
                  showUserSettingsModal={this.showUserSettingsModal}
                  handleClickStart={this.handleClickStart}
                  OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                />
              </TabPane>

              {activeTab && constants.BUY_PAGE_TABS.includes(activeTab) && windowWidth <= 1280 &&
                <TabPane className="layout-mobile-body-pane" tabId={activeTab}>
                  <BuyMobilePage
                    toggleTerms={() => this.toggleBottomBarTab('termsOfUse')}
                    toggleAboutUs={() => this.toggleBottomBarTab('aboutPage')}
                    hasActiveRegistration={hasActiveRegistration}
                    achievementsNotification={achievementsNotification}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                    showUserSettingsModal={this.showUserSettingsModal}
                    screenMode={screenMode}
                    toggleStripeLoading={this.toggleStripeLoading}
                    switchToggle={this.toggle}
                    parentActiveTab={activeTab}
                    isFullscreen={isFullscreen}
                    isFullscreenEnabled={isFullscreenEnabled}
                    toggleFullscreen={toggleFullscreen}
                  />
                </TabPane>
              }

              {activeTab && activeTab === '6' && (
                <TabPane className="layout-mobile-body-pane" tabId="6">
                  <TournamentsMobile
                    changeTab={this.toggle}
                    screenMode={screenMode}
                    toggle={this.toggle}
                    joinRoom={joinRoom}
                    activeTab={activeTab}
                    hasActiveRegistration={hasActiveRegistration}
                    achievementsNotification={achievementsNotification}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                  />
                </TabPane>
              )}

              {activeTab && activeTab === '16' && (
                <TabPane className="layout-mobile-body-pane" tabId="16">
                  <TournamentsPlanMobile
                    changeTab={this.toggle}
                    screenMode={screenMode}
                    toggle={this.toggle}
                    activeTab={activeTab}
                    hasActiveRegistration={hasActiveRegistration}
                    achievementsNotification={achievementsNotification}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                    openModalNotif={openModal}
                    modalTypeNotif={modalType}
                    updateModalNotif={this.UpdateModal}
                  />
                </TabPane>
              )}

              {activeTab && activeTab === '7' && (
                <TabPane className="layout-mobile-body-pane" tabId="7">
                  <TournamentsHistoryMobile
                    changeTab={this.toggle}
                    screenMode={screenMode}
                    toggle={this.toggle}
                    activeTab={activeTab}
                    hasActiveRegistration={hasActiveRegistration}
                    achievementsNotification={achievementsNotification}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                  />
                </TabPane>
              )}
              {activeTab && activeTab === '9' && windowWidth <= 1280 && (
                <TabPane className="layout-mobile-body-pane" tabId="9">
                  <FriendsMobile
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    screenMode={screenMode}
                    activeTab={activeTab}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                  />
                </TabPane>
              )}
              {activeTab && activeTab === '10' && windowWidth <= 1280 && (
                <TabPane className="layout-mobile-body-pane" tabId="10">
                  <IgnoredUsersMobile
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    screenMode={screenMode}
                    activeTab={activeTab}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                    fontMode={fontMode}
                    ignoredSwitchChecked={ignoredSwitchChecked}
                    toggleIgnoredSwitchChecked={this.toggleIgnoredSwitchChecked}
                  />
                  {/* <IgnoredUsers
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    screenMode={screenMode}
                    fontMode={fontMode}
                  /> */}
                </TabPane>
              )}
              {windowWidth <= 1280 && (
                <TabPane className="layout-mobile-body-pane" tabId="11">
                  <AchievementsMobile
                    activeTab={activeTab}
                    changeTab={this.toggle}
                    screenMode={screenMode}
                    achievementsNotification={achievementsNotification}
                    setAchievementsNotification={this.setAchievementsNotification}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                  />
                </TabPane>
              )}
              {activeTab && activeTab === constants.MENU_NAVIGATION.weeklyStatus && windowWidth <= 1280 && (
                <TabPane className="layout-mobile-body-pane" tabId={constants.MENU_NAVIGATION.weeklyStatus}>
                  <WeeklyStatusMobile
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    screenMode={screenMode}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                    activeTab={activeTab}
                  />
                </TabPane>
              )}
              {activeTab && activeTab === constants.MENU_NAVIGATION.gameLogs && windowWidth <= 1280 && (
                <TabPane className="layout-mobile-body-pane" tabId={constants.MENU_NAVIGATION.gameLogs}>
                  <GameLogsMobile
                    changeTab={this.toggle}
                    achievementsNotification={achievementsNotification}
                    screenMode={screenMode}
                    showUserSettingsModal={this.showUserSettingsModal}
                    handleClickStart={this.handleClickStart}
                    OpenMobileDropdownModal={this.OpenMobileDropdownModal}
                    activeTab={activeTab}
                  />
                </TabPane>
              )}
            </TabContent>
              {windowWidth <= 1280 && (
                <LayoutMobileFooter screenMode={screenMode} toggleScreenMode={toggleScreenMode} changeTab={this.toggle} t={t} requestDeleteUser={this.requestDeleteUser} updateProfile={this.updateProfile} userAvatarFileUpload={this.userAvatarFileUpload} />
              )}

              {member && member.newVersion >= constants.NEW_VERSION && !adminReturnToken && !openModal && !holidayModalOpen && !bonusModalOpen && !weeklyDataOpen && !toggleVerifySuccess && (!openAchievementsModal || !achievementsNotification) && windowWidth <= 1280 && (
                <JoyrideComponentMobile run={run} toggle={this.toggle} collapseSidebarTab={this.collapseSidebarTab} closeJoyride={this.closeJoyride} tutorialBonus={member.tutorialBonus || false} openMobileDropdownModal={this.OpenMobileDropdownModal} />
              )}
          </div>

          <TopSettingsSection
            t={t}
            config={config}
            i18n={i18n}
            uid={member.uid}
            newDesign={member.newDesign}
            isFullscreenEnabled={isFullscreenEnabled}
            toggleFullscreen={toggleFullscreen}
            showUserSettingsModal={this.showUserSettingsModal}
            screenMode={screenMode}
            toggleNewDesign={toggleNewDesign}
            fontMode={fontMode}
            toggleFont={toggleFont}
            logout={this.logout}
            toggleHolidayDebuggerModal={this.toggleHolidayDebuggerModal}
            designPlatform={designPlatform}
            activeTab={activeTab}
            adminReturnToken={adminReturnToken}
            returnToAdmin={() => this.returnToAdmin()}
          />

          <BottomBar
            t={t}
            supportModalOpen={supportModalOpen}
            supportChatStatus={member.supportChatStatus}
            toggleBottomBarTab={this.toggleBottomBarTab}
            toggleSupport={this.toggleSupport}
            screenMode={screenMode}
            handleClickStart={(e) => this.handleClickStart(e)}
            platform={platform}
          />

          {member && member.showTournamentResults && member.showTournamentResults.tournamentId && (!achievementsNotification || !openAchievementsModal) && !weeklyDataOpen ? (
            <TournamentResults screenMode={screenMode} />
          ) : (
            null
          )}

          <Clock offset={member.offset} isInGame={false}/>
            </>
        <CustomModal
          isOpen={balanceHistoryModalOpen}
          addClassName="money-history-modal"
          toggle={() => this.toggleBottomBarTab('moneyHistory')}
          size="xl"
          title={t('menu.moneyHistory')}
          body={<MoneyHistory screenMode={screenMode} />}
          footer={(
            <Button color="secondary" onClick={() => this.toggleBottomBarTab('moneyHistory')}>
              {t('common.close')}
            </Button>
          )}
        />

        <CustomModal
          isOpen={pointsHistoryModalOpen}
          addClassName="points-history-modal"
          toggle={() => this.toggleBottomBarTab('pointsHistory')}
          size="xl"
          title={t('menu.pointsHistory')}
          body={<PointsHistory screenMode={screenMode} />}
          footer={(
            <Button color="secondary" onClick={() => this.toggleBottomBarTab('pointsHistory')}>
              {t('common.close')}
            </Button>
          )}
        />

        {bannedUsersModalOpen ? (
          <CustomModal
            isOpen={bannedUsersModalOpen}
            toggle={() => this.toggleBottomBarTab('bannedUsers')}
            size="lg"
            title={t('menu.bannedUsers')}
            addClassName="blocked-players-modal"
            body={<BannedUsers screenMode={screenMode} />}
            footer={(
              <Button color="secondary" onClick={() => this.toggleBottomBarTab('bannedUsers')}>
                {t('common.close')}
              </Button>
            )}
          />
        ) : (null)}

        <CustomModal
          isOpen={aboutModalOpen}
          toggle={() => this.toggleBottomBarTab('aboutPage')}
          size="lg"
          title={t('menu.aboutPage')}
          addClassName="about-us-modal-wrapper"
          body={(
            <AboutUs i18n={i18n} t={t} toggle={() => this.toggleBottomBarTab('aboutPage')} />
          )}
        // footer={(
        //   <Button color="secondary" onClick={() => this.toggleBottomBarTab('aboutPage')}>
        //     {t('common.close')}
        //   </Button>
        // )}
        />

        <CustomModal
          isOpen={termsOfUseOpen}
          toggle={() => this.toggleBottomBarTab('termsOfUse')}
          size="lg"
          title={t('home.termsOfUse')}
          body={(
            <TermsOfUsePage
              playButtonSound={playButtonSound}
              toggleHelp={() => this.toggleBottomBarTab('termsOfUse')}
              cookiesFrom={cookiesFrom}
            />
          )}
          footer={(
            <Button color="secondary" onClick={() => this.toggleBottomBarTab('termsOfUse')}>
              {t('common.close')}
            </Button>
          )}
        />


        <CustomModal
          isOpen={helpPageOpen}
          toggle={() => this.toggleBottomBarTab('helpPage')}
          size={socProvider === constants.SOC_PROVIDERS.draugiem ? 'xl' : 'xlg'}
          title={t('menu.helpPage')}
          addClassName="game-help-modal"
          body={(
            <RulesPage
              playButtonSound={playButtonSound}
              toggleHelp={() => this.toggleBottomBarTab('helpPage')}
              modalFlag
            />
          )}
          footer={(
            <Button color="secondary" onClick={() => this.toggleBottomBarTab('helpPage')}>
              {t('common.close')}
            </Button>
          )}
        />

        <CustomModal
          isOpen={openNewHelpModal}
          toggle={() => this.toggleNewHelpModal()}
          size='xlg'
          title={t('menu.helpPage')}
          addClassName="game-help-modal"
          body={(
            <NewHelpPage
              playButtonSound={playButtonSound}
              modalFlag
            />
          )}
          footer={(
            <Button color="secondary" onClick={() => this.toggleNewHelpModal()}>
              {t('common.close')}
            </Button>
          )}
        />

        <CustomModal
          isOpen={quitModal}
          toggle={this.closeQuitModal}
          size="lg"
          title={t('home.confirm')}
          body={(
            <div className="d-flex align-items-center notification-body logout-confirm-modal-body">
              <Media src={logout} alt="logout-image" />
              {t('common.quitConfirm')}
            </div>
          )}
          footer={(
            <>
              <Button className="btn notification-footer-button" onClick={this.approveQuitModal}>
                {t('common.yes')}
              </Button>
              <Button type="button" className="btn notification-footer-button" onClick={this.closeQuitModal}>
                {t('common.no')}
              </Button>
            </>
          )}
        />

        <CustomModal
          isOpen={toggleVerifySuccess}
          toggle={this.toggleVerifySuccessModal}
          size="md"
          title={t('results.zole')}
          body={(
            <div className="verify-success-modal d-flex align-items-center">
              <Label>{t('home.verifySuccessContent')}</Label>
            </div>
          )}
          footer={(
            <>
              <Button className="btn notification-footer-button" onClick={this.toggleVerifySuccessModal}>
                {t('common.ok')}
              </Button>
            </>
          )}
        />
        {
          achievementsNotification && openAchievementsModal && !holidayModalOpen && !weeklyDataOpen && member && member.newVersion >= constants.NEW_VERSION && !openModal && (
            <CustomModal
              isOpen={achievementsNotification && openAchievementsModal && !holidayModalOpen && !weeklyDataOpen && member && member.newVersion >= constants.NEW_VERSION}
              addClassName="achievement-unlock"
              toggle={this.closeAchievementsModal}
              //size="lg"
              width={910}
              title={t('myInfo.newAchievement')}
              body={<AchievementsModal />}
              footer={(
                <>
                  <Button color="secondary" onClick={this.openAchievements}>
                    {t('common.viewAll')}
                  </Button>
                  <Button color="secondary" onClick={this.closeAchievementsModal}>
                    {t('common.close')}
                  </Button>
                </>
              )}
            />
          )
        }

        <CustomModal
          isOpen={mobileDropdownModalOpened}
          addClassName="mobile-dropdown-menu-modal"
          // inlineClassName="d-none"
          toggle={this.CloseMobiledropdownModal}
          body={(
            <DropdownMenuModalContent
              t={t}
              i18n={i18n}
              supportModalOpen={supportModalOpen}
              notificationSettings={notificationSettings}
              supportChatStatus={member.supportChatStatus}
              toggleBottomBarTab={this.toggleBottomBarTab}
              toggleSupport={this.toggleSupport}
              screenMode={screenMode}
              handleClickStart={(e) => this.handleClickStart(e)}
              config={config}
              uid={member.uid}
              isFullscreenEnabled={isFullscreenEnabled}
              toggleFullscreen={this.toggleFullscreenWrapper}
              showUserSettingsModal={this.showUserSettingsModal}
              toggleNewDesign={toggleNewDesign}
              fontMode={fontMode}
              toggleFont={toggleFont}
              logout={this.logout}
              roomStatus={false}
              closeModal={this.CloseMobiledropdownModal}
              designPlatform={designPlatform}
              activeTab={activeTab}
            />
          )}
          footerClose={false}
        />

        <CustomModal
          isOpen={roomEndedModalOpen}
          toggle={() => setRoomEndedFunction(false)}
          size="xl"
          title={t(balance < 0 ? 'myInfo.negativeBalanceModalTitle' : 'myInfo.lowBalanceModalTitle')}
          body={(
            <RoomEndedModal balance={balance} screenMode={screenMode} buyMoney={this.buyMoney} t={t} />
          )}
        />

        <CustomModal
          isOpen={buyModal}
          toggle={this.toggleBuyModal}
          size="lg"
          title={t('paymentType.chooseTitle')}
          addClassName={"payment-type-content-modal"}
          body={(
            <PaymentTypeContent
              toggleAboutUs={() => this.toggleBottomBarTab('aboutPage')}
              toggleTerms={() => this.toggleBottomBarTab('termsOfUse')}
              selectedProduct={selectedProduct}
              selectedPaymentOption={selectedPaymentOption}
              setSelectedPaymentOption={this.onPaymentOptionClick}
              checkedTerms={checkedTerms}
              toggleCheckedTerms={this.toggleCheckedTerms}
              t={t}
            />
          )}
          footer={(
            <>
              <Button className="payment-type-continue-button mb-3" color="secondary" onClick={this.pay} disabled={!isOnline}>
                {t('common.continue')}
              </Button>
              <hr className={`payment-type-line-${screenMode}`} />
              <p className={`payment-type-footnote-text-${screenMode}`}>SIA 'Draxo Games'</p>
              <p className={`payment-type-footnote-text-${screenMode}`}>Liepājas 49-6, Kuldīga</p>
              <p className={`payment-type-footnote-text-${screenMode}`}>Latvija</p>
            </>
          )}
        />

        <CustomModal
          isOpen={paymentErrorModal}
          toggle={() => this.togglePaymentErrorModal('')}
          size="lg"
          verticalCentering
          largeText
          title={t('common.zoleInfo')}
          body={(
            <p className="text-center">{paymentError}</p>
          )}
          footer={(
            <Button className="payment-type-continue-button mb-3" color="secondary" onClick={() => this.togglePaymentErrorModal('')}>
              {t('common.ok')}
            </Button>
          )}
        />

        <CustomModal
          isOpen={openHolidayDebugger}
          toggle={() => this.toggleHolidayDebuggerModal()}
          size="lg"
          verticalCentering
          largeText
          title={'debugger'}
          inlineClassName={openTestHolidayType && 'holiday-test-section-modal-hide-title'}
          forceBackdropEnabled
          body={(
            <div className={`holiday-test-section d-flex align-items-center flex-column holiday-test-section-${screenMode}`}>
              <Label>Debugger Type</Label>
              <CustomDropdown
                toggle={(
                  <span>
                    {selectedTestHoliday[0].label}
                  </span>
                )
                }
                toggleFunc={this.toggleTestHolidayType}
                isOpen={openTestHolidayType}
                menu={(
                  <>
                    {map(constants.HOLIDAY_TYPES, item => (
                      <DropdownItem key={item.id} className={item.id === selectedTestHoliday[0].id ? 'active-item' : ''} onClick={() => { this.handleSelectTestHoliday(item.id); }}>
                        {item.label}
                      </DropdownItem>
                    ))}
                  </>
                  )}
                inlineMenuClassName="holiday-test-section-dropdown-list"
              />
            </div>
          )}
          footer={(
            <>
              <Button className="payment-type-continue-button mb-3" color="secondary" onClick={() => this.setHolidayTestModal()}>
                Ok
              </Button>
              <Button color="link" className="layout-default-button-important" onClick={() => this.toggleHolidayDebuggerModal()}>
                Close
              </Button>
            </>
          )}
        />
          <NewVersion hidden={adminReturnToken} t={t} openChangeLogVersionModal={openChangeLogVersionModal} closeTriggerChangeLogModal={this.closeTriggerChangeLogModal} screenMode={screenMode} />
        </Fragment>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  claimedAchievements: state.member.claimedAchievements || {},
  userAchievements: state.member.userAchievements || {},
  gamesPlayed: state.member.gamesPlayed || 0,
  gamesWon: state.member.gamesWon || 0,
  roomEnded: state.rooms.roomEnded || false,
  tournamentRoomEnded: state.rooms.tournamentRoomEnded || false,
  balance: parseFloat(state.member.balance) || 0,
  socProvider: state.member.socProvider,
  weeklyDataOpen: state.member.weeklyDataOpen || false,
  joyrideEndedTrigger: state.state.joyrideEndedTrigger || false,
  role: state.member.role || null,
  lastShopTab: state.shop.lastShopTab || null,
  transactionComplete: state.shop.transactionComplete || false,
  campaignData: state.shop.campaignLobbyData || {},
  adminReturnToken: state.users.adminReturnToken || null,
  activeTab: state.state.activeTab,
  pwOption: state.state.pwOption,
  ignoredSwitchChecked: state.state.ignoredSwitchChecked,
  joinedRoom: state.member.joinedRoom,
});

const mapDispatchToProps = {
  fetchAchievements: getAchievements,
  claimAchievementFunc: claimAchievement,
  initEveryPay: initEveryPayPayment,
  setRoomEndedFunction: setRoomEnded,
  resetError: resetRoomError,
  setJoyrideEndedTriggerFunc: setJoyrideEndedTrigger,
  setScreenView: setUserScreenView,
  closeLevelUpNotification: closeLevelNotification,
  setTournamentRoomEndedFunction: setTournamentRoomEnded,
  // fetchCampaign: getCampaignOpenRoomBanner,
  fetchCampaign: getCampaignLobbyBanner,
  setActiveTabFunc: setActiveTab,
  setPwOptionFunc: setPwOption,
  setIgnoredSwitchCheckedFunc: setIgnoredSwitchChecked,
  returnToAdmin: returnToAdminReturnToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Menu));
